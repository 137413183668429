import React, { useEffect, useMemo, useState } from 'react';
import { types as api } from '@mesa-labs/mesa-api';
import { useAbTest } from '@mesa-labs/mesa-ui';

import useSubRoute from '../hooks/useSubRoute';
import { getBrand, getPartnerIdFromBrand } from '../utils';

export type Brand = 'jll' | 'newmark' | 'cbre' | 'cushwake' | 'tishman' | 'unbranded';

const SIGN_UP_CARD_AB_TEST_NAME = 'SignUpCard';
const SIGN_UP_CARD_AB_TEST_BUCKET_NAMES = {
  BASE: 'BASE',
  BUSINESS_NAME_ONLY: 'BUSINESS_NAME_ONLY',
  NO_INFO_REQUIRED: 'NO_INFO_REQUIRED',
};
const SIGN_UP_CARD_AB_TEST_BUCKETS = [{
  name: SIGN_UP_CARD_AB_TEST_BUCKET_NAMES.BASE,
  weight: 1 / 3,
}, {
  name: SIGN_UP_CARD_AB_TEST_BUCKET_NAMES.BUSINESS_NAME_ONLY,
  weight: 1 / 3,
}, {
  name: SIGN_UP_CARD_AB_TEST_BUCKET_NAMES.NO_INFO_REQUIRED,
  weight: 1 / 3,
}];

type IAppContext = {
  brand: Brand;
  setBrand: React.Dispatch<React.SetStateAction<Brand>>;
  program: api.ProgramCodes;
  setProgram: React.Dispatch<React.SetStateAction<api.ProgramCodes>>;
  signUpCardABTestBucket: keyof typeof SIGN_UP_CARD_AB_TEST_BUCKET_NAMES;
  partnerId: api.Partners;
  setPartnerId: React.Dispatch<React.SetStateAction<api.Partners>>;
  clearSignUpCardABTestBucket: () => void;
};

export const AppContext = React.createContext<IAppContext>({
  brand: 'jll',
  setBrand: () => { },
  program: api.ProgramCodes.NET30,
  setProgram: () => { },
  signUpCardABTestBucket: 'BASE',
  partnerId: api.Partners.JLL,
  setPartnerId: () => { },
  clearSignUpCardABTestBucket: () => { },
});

type AppProviderProps = {
  children: React.ReactNode;
};

export function AppContextProvider({ children }: AppProviderProps) {
  const [brand, setBrand] = useState<Brand>('unbranded');
  const [program, setProgram] = useState<api.ProgramCodes>(api.ProgramCodes.NET30);
  const [partnerId, setPartnerId] = useState<api.Partners>(api.Partners.JLL);
  const [ready, setReady] = useState(false);

  const subRoute = useSubRoute();

  // set global program in app context
  useEffect(() => {
    switch (subRoute) {
      case 'pay-on-approval':
        setProgram(api.ProgramCodes.POA);
        break;
      default:
        setProgram(api.ProgramCodes.NET30);
        break;
    }
  }, [subRoute, setProgram]);

  // set initial global brand and partnerId
  useEffect(() => {
    const inferredBrand = getBrand();
    const inferredPartnerId = getPartnerIdFromBrand(inferredBrand);

    if (inferredBrand) {
      setBrand(inferredBrand);
    }

    if (inferredPartnerId) {
      setPartnerId(inferredPartnerId);
    }

    setReady(true);
  }, []);

  const signUpCardABTest = useAbTest(SIGN_UP_CARD_AB_TEST_NAME, SIGN_UP_CARD_AB_TEST_BUCKETS);

  const signUpCardABTestBucket = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const preselected = params.get('signUpCardABTestName');

    // preselect bucket if specified in QS
    if (preselected) {
      signUpCardABTest.preselectBucket(preselected);
    }

    return signUpCardABTest.getBucket() as keyof typeof SIGN_UP_CARD_AB_TEST_BUCKET_NAMES;
  }, []);

  const clearSignUpCardABTestBucket = useMemo(() => signUpCardABTest.clearBucket, []);

  const providerValue = useMemo(() => ({
    brand,
    setBrand,
    program,
    setProgram,
    partnerId,
    setPartnerId,
    signUpCardABTestBucket,
    clearSignUpCardABTestBucket,
  }), [brand, setBrand, program, setProgram, partnerId, setPartnerId, signUpCardABTestBucket]);

  return (
    <AppContext.Provider value={providerValue}>
      {ready ? children : null}
    </AppContext.Provider>
  );
}

export const useAppContext = () => React.useContext(AppContext);
