import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import { types as api } from '@mesa-labs/mesa-api';

import MobileNavigation from './MobileNavigation';
import HeroAnimation from './HeroAnimation';
import GetStartedCard from './GetStartedCard';
import getTheme from '../../../theme';
import { Brand } from '../../../utils';
import useReferralCode from '../../../hooks/useReferralCode';
import usePromoCode from '../../../hooks/usePromoCode';
import useAchNumDays from '../../../hooks/useAchNumDays';
import { useAppContext } from '../../../contexts/AppContext';
import DesktopNavigation from './DesktopNavigation';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const FadeInUp = styled.div<{ delay?: number, duration?: number }>`
  animation: ${(props) => props.duration}s ${fadeInUp} ease-in-out forwards;
  animation-delay: ${(props) => props.delay || 0}s;
  opacity: 0;
  will-change: transform;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    animation: none;
    animation-delay: 0s;
    opacity: 1;
  }
`;

const HeroContainer = styled.div`
  background: linear-gradient(140deg, #4054ED, #1B2CAC, #4054ED, #1B2CAC);
  background-size: 400% 400%;
  border-radius: 20px;
  color: ${(props) => props.theme.colors.White};
  margin-bottom: 180px;
  max-height: 890px;
  padding-top: 62px;
  position: relative;
  width: 100%;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    margin-bottom: 90px;
    max-height: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    border-radius: 0px;
    max-height: none;
    padding-top: 16px;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    padding: 0px;
  }
`;

const NavigationContainer = styled.div`
  width: 100%;  
`;

const HeroContent = styled.div<{ brand: Brand }>`
  display: grid;
  grid-template-columns: auto 814px;
  grid-template-rows: minmax(880px, auto);
  height: 100%;
  margin-top: ${(props) => (props.brand === 'unbranded' && 124) || 80}px;
  overflow: hidden;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    grid-template-columns: 100%;
    margin-top: 60px;
    text-align: left;
  }
`;

const GetInTouchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    max-width: 500px;
    text-align: left;
  }
`;

const GetInTouchText = styled.div`
  color: ${(props) => props.theme.colors.White};
  font-size: 14px;
  line-height: 21px;
  width: 300px;
`;

const GetInTouchButton = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.White};
  border-radius: 28px;
  border: 1px solid #4054ED;
  color: ${(props) => props.theme.colors.Cobalt};
  box-shadow: 0px 0.887541px 1.77508px rgba(64, 84, 237, 0.5), inset 0px -2.66262px 5.32525px rgba(64, 84, 237, 0.2);
  cursor: pointer;
  display: flex;
  font-weight: 500;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 24px;
  width: 170px;
`;

const GetInTouchButtonIcon = styled(motion.img)``;

const HeroLeft = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 1;
  padding-left: 114px;

  @media (max-width: ${(props) => props.theme.breakpoints.Desktop}) {
    padding-left: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding-left: 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 0px 28px;
  }
`;

const HeroRight = styled.div`
  flex-grow: 1;
  height: 100%;
  min-width: 760px;
  position: relative;
  transform: translate(0%, 60px);
  z-index: 10;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    transform: none;
  }
`;

const HeroGraphicMobile = styled.img`
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.Laptop}) {
    display: none;
  }
`;

const Header = styled.div<{ brand: Brand }>`
  color: ${(props) => props.theme.colors.White};
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: ${(props) => (props.brand === 'unbranded' && 60) || 76}px;
  font-weight: 500;
  line-height: 112%;
  margin: 0px 0px 32px 0px;
  width: 566px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 56px;
    max-width: 480px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    font-size: 48px;
    max-width: 360px;
  }
`;

const SubHeader = styled.div`
  color: ${(props) => props.theme.colors.White};
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 42px;
  width: 420px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin: 0px auto 52px auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    width: auto;
  }
`;

const SignInText = styled.div`
  font-size: 15px;
  font-weight: 500;
  justify-content: flex-start;
  line-height: 21px;
  color: ${(props) => props.theme.colors.White};
`;

const SignInLink = styled.span`
  cursor: pointer;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
`;

type HeroProps = {
  setSignUpInquiryModalOpen: (open: boolean) => void;
  heroDesktopNavigationRef: React.MutableRefObject<HTMLDivElement | null>;
  subRoute: string;
};

function Hero({ setSignUpInquiryModalOpen, subRoute, heroDesktopNavigationRef }: HeroProps) {
  const { brand, program } = useAppContext();

  const brandEntity = useMemo(() => {
    switch (brand) {
      case 'cbre':
        return 'CBRE';
      case 'cushwake':
        return 'Cushman and Wakefield';
      case 'newmark':
        return 'Newmark';
      case 'tishman':
        return 'Tishman Speyer';
      case 'jll':
        return 'JLL';
      default:
        return '';
    }
  }, [brand]);

  const referralCode = useReferralCode();
  const promoCode = usePromoCode();
  const achNumDays = useAchNumDays({ brand, referralCode, promoCode });
  const theme = getTheme(brand);

  const headerCopy = useMemo(() => {
    if (program === api.ProgramCodes.POA) {
      return (
        <>
          Introducing Pay
          <br />
          on Approval
        </>
      );
    }

    switch (brand) {
      case 'unbranded':
        return 'Provide early payments that empower your suppliers';
      default:
        return `Get paid in ${achNumDays} days via ACH`;
    }
  }, [brand, program]);

  return (
    <HeroContainer>
      <NavigationContainer>
        <MobileNavigation />

        <div ref={heroDesktopNavigationRef}>
          <DesktopNavigation
            subRoute={subRoute}
            setSignUpInquiryModalOpen={setSignUpInquiryModalOpen}
          />
        </div>
      </NavigationContainer>

      <HeroContent brand={brand}>
        <HeroLeft>
          <FadeInUp duration={0.4} delay={1.75}>
            <Header brand={brand}>{headerCopy}</Header>
          </FadeInUp>

          {(brand !== 'unbranded' || program === api.ProgramCodes.POA) && (
            <div>
              <FadeInUp duration={0.4} delay={2}>
                <SubHeader>
                  {
                    program === api.ProgramCodes.POA ? (
                      <>
                        Get paid via ACH as soon as your invoices are approved.
                      </>
                    ) : (
                      <>
                        Accelerate payments
                        {' '}
                        to net-
                        {achNumDays}
                        {' '}
                        or sooner on all your
                        {` ${brandEntity} `}
                        invoices. No more delays. All via ACH.
                      </>
                    )
                  }
                </SubHeader>
              </FadeInUp>

              <FadeInUp duration={0.4} delay={2.25}>
                <GetStartedCard />
              </FadeInUp>

              <FadeInUp duration={0.4} delay={2.5}>
                <SignInText>
                  Have a question?
                  {' '}
                  <SignInLink
                    onClick={() => {
                      setSignUpInquiryModalOpen(true);
                    }}
                  >
                    Contact us
                  </SignInLink>
                </SignInText>
              </FadeInUp>
            </div>
          )}

          {(brand === 'unbranded' && program !== api.ProgramCodes.POA) && (
            <GetInTouchContainer>
              <FadeInUp duration={0.4} delay={1.25}>
                <GetInTouchButton onClick={() => setSignUpInquiryModalOpen(true)}>
                  Get in touch
                  <GetInTouchButtonIcon src="/assets/right-arrow-icon.svg" />
                </GetInTouchButton>
              </FadeInUp>

              <FadeInUp duration={0.4} delay={1.5}>
                <GetInTouchText>
                  Free for payers of invoices, start providing early payment
                  options for your valued partners today.
                </GetInTouchText>
              </FadeInUp>
            </GetInTouchContainer>
          )}
        </HeroLeft>

        <HeroRight>
          <HeroAnimation />
          <HeroGraphicMobile src={theme.dynamicContent.heroGraphicImageMobile} />
        </HeroRight>
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;
