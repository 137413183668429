import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';

import App from './components/App';
import { AppContextProvider } from './contexts/AppContext';

Sentry.init({
  dsn: CONFIG.sentry.dsn,
  environment: import.meta.env.MODE,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: CONFIG.sentry.tracesSampleRate,
  normalizeDepth: 5,
});

mixpanel.init(CONFIG.mixpanel.token, { debug: true });

const root = ReactDOM.createRoot(document.querySelector('#root')!);

root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>,
);
