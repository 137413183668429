import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';

type GenericEventData = Record<string, unknown>;

export const MarketingEvents = {
  PageView: 'marketing-page-view',
  Net30PageView: 'marketing-net-30-page-view',
  PayOnApprovalPageView: 'marketing-pay-on-approval-page-view',
  HeaderGetStartedButtonClick: 'marketing-header-get-started-button-click',
} as const;

export const MarketingABTestingEvents = {
  SignUpCardBase: 'marketing-sign-up-card-base',
  SignUpCardBusinessNameOnly: 'marketing-sign-up-card-business-name-only',
  SignUpCardNoInfoRequired: 'marketing-sign-up-card-no-info-required',
} as const;

const AllEvents = {
  ...MarketingEvents,
  ...MarketingABTestingEvents,
};

type AllEventTypeValues = typeof AllEvents[keyof typeof AllEvents];

export const setAnalyticsGroup = (groupName: string, group: number | string) => {
  try {
    mixpanel.set_group(groupName, group.toString());
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const addAnalyticsGroup = (groupName: string, group: number | string) => {
  try {
    mixpanel.add_group(groupName, group.toString());
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const trackEvent = <T = GenericEventData>(eventName: AllEventTypeValues, eventData: Partial<T> = {}) => {
  try {
    mixpanel.track(eventName, eventData);
  } catch (err) {
    Sentry.captureException(err);
  }
};
