import React, { ReactElement, useEffect } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ErrorScreen, StyleSheetManager } from '@mesa-labs/mesa-ui';

import getTheme from '../theme';
import usePromoCode from '../hooks/usePromoCode';
import AppRoutes from '../routes';
import {
  MarketingABTestingEvents,
  MarketingEvents,
  setAnalyticsGroup,
  trackEvent,
} from '../analytics';
import { useAppContext } from '../contexts/AppContext';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
  }

  body {
    display: flex;
    flex-direction: column;
    font-family: ${(props) => props.theme.fonts.primary};
    overflow-x: hidden; 
    margin: 0px;
    padding: 0px;

    input:focus {
      outline: none;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0px;
    padding: 0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    box-sizing: border-box;
  }

  pre {
    overflow-x: auto;
  }
`;

function App(): ReactElement {
  const promoCode = usePromoCode();
  const { brand, signUpCardABTestBucket } = useAppContext();

  // dynamically set the page title based on current brand:
  useEffect(() => {
    let pageTitle = '';

    switch (brand) {
      case 'jll':
        pageTitle = 'Mesa | JLL';
        break;
      case 'newmark':
        pageTitle = 'Mesa | Newmark';
        break;
      case 'cbre':
        pageTitle = 'Mesa | CBRE';
        break;
      case 'cushwake':
        pageTitle = 'Mesa | Cushman & Wakefield';
        break;
      case 'tishman':
        pageTitle = 'Mesa | Tishman Speyer';
        break;
      case 'unbranded':
      default:
        pageTitle = 'Mesa';
        break;
    }

    document.title = pageTitle;
  }, [brand]);

  // track initial page view event within Mixpanel
  useEffect(() => {
    if (promoCode) {
      setAnalyticsGroup('promoCode', promoCode);
    }

    if (window.location.pathname.includes('net30')) {
      trackEvent(MarketingEvents.Net30PageView);
    } else if (window.location.pathname.includes('pay-on-approval')) {
      trackEvent(MarketingEvents.PayOnApprovalPageView);
    } else {
      trackEvent(MarketingEvents.PageView);
    }
  }, []);

  useEffect(() => {
    switch (signUpCardABTestBucket) {
      case 'BUSINESS_NAME_ONLY':
        trackEvent(MarketingABTestingEvents.SignUpCardBusinessNameOnly);
        break;
      case 'NO_INFO_REQUIRED':
        trackEvent(MarketingABTestingEvents.SignUpCardNoInfoRequired);
        break;
      case 'BASE':
      default:
        trackEvent(MarketingABTestingEvents.SignUpCardBase);
        break;
    }
  }, [signUpCardABTestBucket]);

  return (
    <StyleSheetManager>
      <ThemeProvider theme={getTheme(brand)}>
        <Router>
          <GlobalStyle />
          <Sentry.ErrorBoundary fallback={<ErrorScreen onClick={() => Sentry.showReportDialog()} />}>
            <AppRoutes />
          </Sentry.ErrorBoundary>
        </Router>
      </ThemeProvider>
    </StyleSheetManager>
  );
}

export default App;
