import { motion } from 'framer-motion';
import React, {
  createRef,
  RefObject,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../contexts/AppContext';

const CardHeight = 300;
const CardWidth = 569;

const CustomerReviewsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px auto 180px auto;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin-bottom: 90px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    margin-bottom: 64px;
  }
`;

const Header = styled.div`
  color: #071058;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 56px;
  font-weight: 500;
  line-height: 111%;
  margin-bottom: 90px;
  text-align: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 40px;
    margin-bottom: 48px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const Cards = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: 60px;
  margin-bottom: 65px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-bottom: 24px;
  }
`;

const Card = styled.div`
  align-items: flex-start;
  background: ${(props) => props.theme.colors.White};
  border-radius: 20px;
  border: 2px solid rgba(7, 16, 88, 0.12);
  cursor: grab;
  display: flex;
  height: ${CardHeight}px;
  justify-content: flex-start;
  padding: 40px;
  position: relative;
  width: ${CardWidth}px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    height: calc(${CardHeight}px / 1.25);
    padding: 16px 24px;
    width: calc(${CardWidth}px / 1.25);
  }
`;

const CardImage = styled.div<{ imageSrc: string }>`
  background-image: url(${(props) => props.imageSrc});
  background-size: contain;
  background-position: center center;
  border-radius: 50%;
  flex-shrink: 0;
  height: 112px;
  margin-right: 38px;
  width: 112px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    height: 64px;
    margin-right: 16px;
    width: 64px;
  }
`;

const CardReviewContainer = styled.div`
  height: 100%;
`;

const CardReview = styled.div`
  color: #071058;
  font-size: 15px;
  line-height: 140%;
  margin-bottom: 22px;
  opacity: 0.9;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    margin-bottom: 12px;
  }
`;

const CardReviewer = styled.div`
  color: #071058;
  font-size: 12px;
  font-weight: 600;
  line-height: 140%;
  margin-bottom: 4px;
`;

const CardReviewerTitle = styled.div`
  color: #071058;
  font-size: 14px;
  line-height: 140%;
  opacity: 0.7;
`;

const QuoteIcon = styled.img`
  bottom: -12px;
  position: absolute;
  right: 64px;

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    transform: scale(.75);
    right: 24px;
  }
`;

const Steppers = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0px auto;
`;

const Stepper = styled.div<{ opacity: string }>`
  background: #071058;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 15px;
  opacity: ${(props) => props.opacity};

  &:last-of-type {
    margin-right: 0px;
  }
`;

function CustomerReviews() {
  const dragConstraintsRef = useRef<HTMLDivElement>(null);
  const [lastDragPoint, setLastDragPoint] = useState(0);

  const { brand } = useAppContext();
  const reviews = useMemo(() => ([{
    id: 1,
    text: `As you could imagine, long payment terms have posed many challenges. Mesa allows me the freedom to continue being adaptable for my clients and to expand my scope of work ${brand === 'jll' ? 'with JLL' : ''} because I know I'll always get paid in 30 days.`,
    reviewer: 'Michele McClain',
    reviewerTitle: 'Founder, CEO LakeShore Scientific',
    imgSrc: 'MicheleMcClain.jpg',
  }, {
    id: 2,
    text: `We've been working ${brand === 'jll' ? 'with JLL' : ''} for many years and have declined certain work because the payment terms were too long. Mesa has unlocked two major partners for us that we previously couldn't support due to extended payment terms, which represents meaningful growth for our business${brand === 'jll' ? ' at JLL' : ''}.`,
    reviewer: 'Melissa S.',
    reviewerTitle: 'Clifford Power',
    imgSrc: 'MelissaS.jpeg',
  }, {
    id: 3,
    text: 'Mesa gets me access to my invoice payments sooner so I can invest in growing my business. This is a huge improvement to my cash flow and operations. The program is working really well for me.',
    reviewer: 'Sabrina Williams',
    reviewerTitle: 'CEO Southern Perfection Painting',
    imgSrc: 'SabrinaWilliams.jpeg',
  }]), [brand]);

  const cardRefs = useRef<RefObject<HTMLDivElement>[]>(reviews.map(() => createRef<HTMLDivElement>()));

  const activeReview = useMemo(() => {
    if (!cardRefs.current) {
      return 0;
    }

    const index = cardRefs.current.findIndex((ref) => (
      ref.current && ref.current.getBoundingClientRect().x >= 0
    ));

    return index > 0 ? index : 0;
  }, [cardRefs.current, lastDragPoint]);

  return (
    <CustomerReviewsContainer>
      <Header>Customer Reviews</Header>

      <CardsContainer ref={dragConstraintsRef}>
        <Cards
          drag="x"
          dragConstraints={dragConstraintsRef}
          whileDrag={{ scale: 1.02 }}
          onDragEnd={(event, info) => {
            setLastDragPoint(info.point.x);
          }}
        >
          {
            reviews.map((review, idx) => (
              <Card key={review.id} ref={cardRefs.current[idx]}>
                <CardImage imageSrc={`/assets/${review.imgSrc}`} />

                <CardReviewContainer>
                  <CardReview>{review.text}</CardReview>
                  <CardReviewer>{review.reviewer}</CardReviewer>
                  <CardReviewerTitle>{review.reviewerTitle}</CardReviewerTitle>
                </CardReviewContainer>

                <QuoteIcon src="/assets/quote-icon.svg" />
              </Card>
            ))
          }
        </Cards>
      </CardsContainer>

      <Steppers>
        {cardRefs.current.map((_, idx) => (
          <Stepper
            key={reviews[idx].id}
            opacity={activeReview === idx ? '1' : '0.15'}
          />
        ))}
      </Steppers>
    </CustomerReviewsContainer>
  );
}

export default CustomerReviews;
