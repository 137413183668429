import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageFooter, Typography } from '@mesa-labs/mesa-ui';
import PageHeader from './PageHeader';

const PrivacyPolicyContainer = styled.div`
  ol, ul {
    padding: 0px 32px;
  }

  li {
    padding: 2px 0px;
  }
`;

const PrivacyPolicyHeader = styled(Typography.H1)`
  margin-bottom: 40px;
`;

const PrivacyPolicySection = styled(Typography.BodyMedium)`
  margin-bottom: 24px;
`;

const PrivacyPolicySectionTitle = styled(Typography.BodySubheader)`
  font-weight: 500;
  margin-bottom: 8px;
`;

const PrivacyPolicySectionSubTitle = styled(Typography.BodyLarge)`
  margin-bottom: 8px;
`;

const Line = styled.p`
  margin-bottom: 16px;
`;

const ExternalLink = styled.a`
  text-decoration: underline;
`;

const LegalContainer = styled.div`
`;

const Content = styled.div`
  max-width: 1360px;
  margin: 0px auto 48px auto;
  padding: 60px 80px 0px 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 40px;
  }
`;

const LegalTitle = styled(Typography.Title)`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 32px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: left;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const SectionTitle = styled(Typography.BodySubheader)`
  font-size: 19px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const LinksContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 11px 25px rgb(45 49 52 / 7%);
  padding: 48px;
  margin-bottom: 80px;
`;

const LinkContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 4px;
  text-decoration: underline;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

function PrivacyPolicy(): ReactElement {
  return (
    <LegalContainer>
      <PageHeader />

      <Content>
        <LinksContainer>
          <LegalTitle>Legal Documents</LegalTitle>

          <Section>
            <SectionTitle>Terms</SectionTitle>

            <LinkContainer>
              <Link to="/legal/terms-of-service">Terms of Service</Link>
            </LinkContainer>
          </Section>

          <Section>
            <SectionTitle>Policies</SectionTitle>

            <LinkContainer>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </LinkContainer>

            <LinkContainer>
              <Link to="/legal/electronic-consent">Eletronic Consent</Link>
            </LinkContainer>
          </Section>
        </LinksContainer>

        <PrivacyPolicyContainer>
          <PrivacyPolicyHeader>Mesa Privacy Policy</PrivacyPolicyHeader>

          <PrivacyPolicySectionTitle>
            OUR COMMITMENT TO PRIVACY
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              Mesa Financial, Inc. (“Mesa”) knows that you care about how your personal
              information is used and shared, and we take your privacy seriously. Our
              primary goal is to provide you with exceptional service, and we understand
              that you may have questions or concerns regarding your personal
              information and how it will be used. To make this privacy policy easy to
              find, we make it available from the home page of the Mesa website at
              joinmesa.com the (“Site”). You may also email us at support@joinmesa.com
              with any privacy-related questions you have.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            APPLICABILITY OF PRIVACY POLICY
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              This privacy policy applies to all information we collect through our
              web and mobile applications from current and former Mesa users,
              including you. When you are no longer our customer, we may continue to
              share your information as described in this policy.
            </Line>
            <Line>
              Throughout this policy, we use the term “personal information” to
              describe information that can be associated with a specific person and
              can be used to identify that person. We do not consider personal
              information to include information that has been aggregated and/or
              anonymized so that it does not identify a specific user.
            </Line>
            <Line>
              By using the products and services offered on the Site (collectively,
              the “Services”), you agree to the practices described in this Privacy
              Policy. If you do not agree to this Privacy Policy, please do not
              access the Site or otherwise use the Services.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            PERSONAL DATA WE COLLECT
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySectionSubTitle>
            Personal Data You Provide
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              We collect personal information when you create an account, link your
              bank account(s) to the Service, or receive money through the platform.
              The personal information collected during these interactions may vary
              based on what you choose to share with us, but it will generally
              include your name, business name, email address, phone number, bank
              account information (such as account/routing numbers and transaction
              data), and a log of the transactions you authorize. We may need to
              collect additional information, such as your street address, zip code,
              date of birth, certain digits of your social security number, and
              government-issued form of identification to fulfill our legal
              obligations related to providing the Service to you. In addition, we
              may collect your location information.

            </Line>
          </PrivacyPolicySection>
          <PrivacyPolicySectionSubTitle>
            Personal Data We Receive Automatically from Your Use of the Service
          </PrivacyPolicySectionSubTitle>

          <PrivacyPolicySection>
            <Line>
              When you visit, use and
              interact with the Site or use the Services, we may receive certain
              information about your visit, use or interactions. For example, we may
              monitor the number of people that visit the Site, peak hours of
              visits, which page(s) are visited, the domains our visitors come from
              (e.g., google.com, yahoo.com, etc.), and which browsers people use to
              access the Service (e.g., Firefox, Microsoft Internet Explorer, etc.),
              broad geographical information, and navigation pattern. In particular,
              the following information is created and automatically logged in our
              systems:
            </Line>

            <ul>
              <li>
                Log data: Information that your browser automatically sends
                whenever you visit the Site (“Log data”). Log data includes your
                Internet Protocol address, browser type and settings, the date and
                time of your request, and how you interacted with the Service.
              </li>

              <li>
                Cookies: Please see the “Cookies” section below to learn more about
                how we use cookies.
              </li>

              <li>
                Device information: Includes name of the device,
                operating system, and browser you are using. Information collected may
                depend on the type of device you use and its settings.
              </li>

              <li>
                Usage Information: We collect information about how you use our Service,
                such as the types of content that you view or engage with, the
                features you use, the actions you take, and the time, frequency and
                duration of your activities.
              </li>
            </ul>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Third Party Credentials, Account Numbers and Other Account Information
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              When you use certain Services, we may collect from you your usernames,
              passwords, account numbers, and other account information and use or
              share that information with third-party websites and Internet banking
              services that are not operated or controlled by Mesa (“Third-Party
              Sites”) in order to provide the Services to you. We also collect
              account information from you when you open a Mesa Account and we may
              share that information with our banking partner(s) and third-party
              providers in order to offer the Services to you. Information you
              provide to us may be used to obtain your account, transaction, and
              other banking information from the relevant organization on your
              behalf, to display the information to you, or to fulfill your requests
              for certain products, services, or transactions through the Service.
            </Line>

            <Line>
              By using our Services, you grant Mesa and each Third-Party Site the
              right, power, and authority to act on your behalf to access and
              transmit your information in order to provide the Services. In
              addition, you agree that to the extent that your information is in the
              possession of a Third-Party Site, such information is subject to the
              privacy policy of that Third-Party Site.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            LINKS TO OTHER WEBSITES
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              The Service may contain links to other websites not operated or
              controlled by Mesa, including social media services.  The information
              that you share with those third parties will be governed by the
              specific privacy policies and terms of service of the third party
              sites, respectively and not by this Privacy Policy. By providing such
              links we do not imply that we endorse or have reviewed the third party
              sites. Please contact such third party sites directly for information
              on their privacy practices and policies.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Cookies
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              We use cookies to operate and administer our Site, gather usage data on
              our Site and improve your experience on it. A “cookie” is a piece of
              information sent to your browser by a website you visit. Cookies can be
              stored on your computer for different periods of time. Some cookies expire
              after a certain amount of time, or upon logging out (session cookies),
              others survive after your browser is closed until a defined expiration
              date set in the cookie (as determined by the third party placing it), and
              help recognize your computer when you open your browser and browse the
              Internet again (persistent cookies). For more details on cookies please
              visit All About Cookies.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Analytics
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              We use Google Analytics, a web analytics service provided by Google,
              Inc. (“Google”). Google Analytics uses cookies to help us analyze how
              users use the Site and enhance your experience when you use the Site.
              For more information on how Google uses this data, go
              towww.google.com/policies/privacy/partners/.
            </Line>

            <Line>
              We use Mixpanel, a web analytics service provided by Mixpanel, Inc.
              (“Mixpanel”). Mixpanel uses cookies to help us analyze how
              users use the Site and enhance your experience when you use the
              Site. For more information on how Mixpanel uses this data, go to
              https://mixpanel.com/legal/privacy-policy
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Online Tracking and Do Not Track Signals
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              We and our third-party service providers may use cookies or other tracking
              technologies to collect information about your browsing activities over
              time and across different websites following your use of the Site. Our
              Site currently does not respond to “Do Not Track” (“DNT”) signals and
              operates as described in this Privacy Policy whether or not a DNT signal
              is received. If we do respond to DNT signals in the future, we will update
              this Privacy Policy to describe how we do so.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Your Choices
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              On most web browsers, you will find a “help” section on the toolbar.
              Please refer to this section for information on how to receive a
              notification when you are receiving a new cookie and how to turn
              cookies off. Please see the links below for guidance on how to modify
              your web browser’s settings on the most popular browsers:
            </Line>
            <ul>
              <li>
                <ExternalLink href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">
                  Internet Explorer
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?esab=a&s=cookies&r=6&as=s&redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
                  Mozilla Firefox
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://support.google.com/accounts/answer/61416?hl=en">
                  Google Chrome
                </ExternalLink>
              </li>
              <li>
                <ExternalLink href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  Apple Safari
                </ExternalLink>
              </li>
            </ul>
            <Line>
              Please note that if you limit the ability of websites to set cookies,
              you may be unable to access certain parts of the Site and you may not
              be able to benefit from the full functionality of the Site.
            </Line>
            <Line>
              Advertising networks may collect personal information through the use
              of cookies. Most advertising networks offer you a way to opt out of
              targeted advertising. If you would like to find out more information,
              please visit the Network Advertising Initiative’s online resources at
              http://www.networkadvertising. org and follow the opt-out instructions
              there.
            </Line>
            <Line>
              If you access the Site on your mobile device, you may not be able to
              control tracking technologies through the settings.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            HOW WE USE PERSONAL DATA
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <ul>
              <li>
                We may use personal information for the following purposes:
              </li>
              <li>
                To help you access and use the Service;
              </li>
              <li>
                To respond to your inquiries, comments, feedback or questions;
              </li>
              <li>
                To send administrative information to you, for example, information
                regarding the Service, and changes to our terms, conditions, and
                policies;
              </li>
              <li>
                To analyze how you interact with our Service;
              </li>
              <li>
                To maintain and improve the content and functionality of the Service
                (please see the “Cookies” section above to learn how we use cookies);
              </li>
              <li>
                To develop new products and Services;
              </li>
              <li>
                To market our Services or the services of our affiliates or business
                partners to you;
              </li>
              <li>
                To prevent fraud, criminal activity, or misuses of our Service, and to
                ensure the security of our IT systems, architecture and networks; and
              </li>
              <li>
                To comply with legal obligations and legal process and to protect our
                rights, privacy, safety or property, and/or that of our affiliates,
                you or other third parties.
              </li>
            </ul>
          </PrivacyPolicySection>

          <PrivacyPolicySectionSubTitle>
            Aggregated Information
          </PrivacyPolicySectionSubTitle>
          <PrivacyPolicySection>
            <Line>
              We may aggregate personal information and use the aggregated
              information to analyze the effectiveness of our Service, to improve
              and add features to our Service, and for other similar purposes. In
              addition, from time to time, we may analyze the general behavior and
              characteristics of users of our Services and share aggregated
              information like general user statistics with prospective business
              partners. We may collect aggregated information through features of
              the software that supports our Service, through cookies, and through
              other means described in this Privacy Policy.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySection>
            <PrivacyPolicySectionSubTitle>Marketing</PrivacyPolicySectionSubTitle>
            <Line>
              We may contact you to tell you about Services we believe will be of
              interest to you. For instance, if you elect to provide your email or
              telephone number, we may use that information to send you special
              offers. You may opt out of receiving emails by following the
              instructions contained in each promotional email we send you. You can
              also control the marketing emails and/or text messages you receive by
              updating your settings through your account. In addition, if at any
              time you do not wish to receive future marketing communications,
              please contact us. If you unsubscribe from our marketing lists, you
              will no longer receive marketing communications, but we will continue
              to contact you regarding our Site and Services and to respond to your
              requests.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            SHARING AND DISCLOSURE OF PERSONAL DATA
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              In certain circumstances we may share your personal information with
              third parties without further notice to you, unless required by the
              law, as set forth below:
            </Line>
            <ul>
              <li>
                Vendors and Service Providers: To assist us in meeting business
                operations needs and to perform certain Services and functions, we may
                share personal information with vendors and service providers,
                including providers of hosting services, cloud services and other
                information technology services providers, email communication
                software and email newsletter services, advertising and marketing
                services, financial service providers (including payment card
                processors, banks, and API services), customer relationship management
                and customer support services, and web analytics services (for more
                details on the third parties that place cookies through the Site,
                please see the “Cookies” section above). Pursuant to our instructions,
                these parties will access, process or store personal information in
                the course of performing their duties to us. We take commercially
                reasonable steps to ensure our service providers adhere to the
                Security standards we apply to your personal information.
              </li>
              <li>
                Business Transfers: If we are involved in a merger, acquisition,
                financing due diligence, reorganization, bankruptcy, receivership,
                sale of all or a portion of our assets, or transition of service to
                another provider, your personal information and other information may
                be shared in the diligence process with counterparties and others
                assisting with the transaction and transferred to a successor or
                affiliate as part of that transaction along with other assets.
              </li>
              <li>
                Legal Requirements: If required to do so by law or in the good faith
                belief that such action is necessary to (i) comply with a legal
                obligation, including to meet national security or law enforcement
                requirements, (ii) protect and defend our rights or property, (iii)
                prevent fraud, (iv) act in urgent circumstances to protect the
                personal safety of users of the Services, or the public, or (v)
                protect against legal liability.
              </li>
              <li>
                Affiliates: We may share personal information with our affiliates,
                meaning an entity that controls, is controlled by, or is under common
                control with Mesa. Our affiliates may use the personal information we
                share in a manner consistent with this Privacy Policy.
              </li>
            </ul>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            DATA RETENTION
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              We keep personal information for as long as reasonably necessary for
              the purposes described in this Privacy Policy, while we have a
              business need to do so, or as required by law (e.g. for tax, legal,
              accounting or other purposes), whichever is the longer.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            UPDATE YOUR INFORMATION
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              Please log in to your account or contact us if you need to change or
              correct your personal information.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            CHILDREN
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              Our Service is not directed to children who are under the age of 13.
              Mesa does not knowingly collect personal information from children
              under the age of 13. If you have reason to believe that a child under
              the age of 13 has provided personal information to Mesa through the
              Service, please contact us and we will endeavor to delete that
              information from our databases.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            SECURITY
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              You use the Services at your own risk. We implement reasonable
              technical, administrative, and organizational measures to protect
              personal information both online and offline from loss, misuse, and
              unauthorized access, disclosure, alteration or destruction. However,
              no Internet or e-mail transmission is ever fully secure or error free.
              In particular, e-mail sent to or from us may not be secure. Therefore,
              you should take special care in deciding what information you send to
              us via the Service or e-mail. Please keep this in mind when disclosing
              any personal information to Mesa via the Internet. In addition, we are
              not responsible for circumvention of any privacy settings or security
              measures contained on the Service, or third party websites.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            YOUR CHOICES
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              In certain circumstances providing personal information is optional.
              However, if you choose not to provide personal information that is
              needed to use some features of our Services, you may be unable to use
              those features. You can also contact us to ask us to update or correct
              your personal information.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            CHANGES TO THE PRIVACY POLICY
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              The Services, and our business may change from time to time. As a
              result, we may change this Privacy Policy at any time. When we do we
              will post an updated version on this page, unless another type of
              notice is required by the applicable law. By continuing to use our
              Service or providing us with personal information after we have posted
              an updated Privacy Policy, or notified you if applicable, you consent
              to the revised Privacy Policy and practices described in it.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            CALIFORNIA PRIVACY RIGHTS - DO NOT TRACK SIGNALS
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              Our Site currently does not respond to “Do Not Track” (DNT) signals
              and operates as described in this Privacy Policy whether or not a DNT
              signal is received. If we do so in the future, we will describe how we
              do so in this Privacy Policy.
            </Line>
          </PrivacyPolicySection>

          <PrivacyPolicySectionTitle>
            CONTACT US
          </PrivacyPolicySectionTitle>
          <PrivacyPolicySection>
            <Line>
              If you have any questions about our Privacy Policy or information
              practices, please feel free to contact us directly.
            </Line>
          </PrivacyPolicySection>
        </PrivacyPolicyContainer>

      </Content>

      <PageFooter />
    </LegalContainer>
  );
}

export default PrivacyPolicy;
