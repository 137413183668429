import React, { ReactElement } from 'react';
import { Link, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { PageFooter, Typography } from '@mesa-labs/mesa-ui';

import PageHeader from './PageHeader';

const LegalContainer = styled.div`
`;

const Content = styled.div`
  max-width: 1360px;
  margin: 0px auto 48px auto;
  padding: 60px 80px 0px 80px;

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 40px;
  }
`;

const LegalTitle = styled(Typography.Title)`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 32px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 32px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  text-align: left;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const SectionTitle = styled(Typography.BodySubheader)`
  font-size: 19px;
  line-height: 19px;
  margin-bottom: 8px;
`;

const LinksContainer = styled.div`
  border-radius: 8px;
  box-shadow: 0px 11px 25px rgb(45 49 52 / 7%);
  padding: 48px;
  margin-bottom: 80px;
`;

const LinkContainer = styled.div`
  flex-basis: 50%;
  margin-bottom: 4px;
  text-decoration: underline;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

function Legal(): ReactElement {
  return (
    <LegalContainer>
      <PageHeader />

      <Content>
        <LinksContainer>
          <LegalTitle>Legal Documents</LegalTitle>

          <Section>
            <SectionTitle>Terms</SectionTitle>

            <LinkContainer>
              <Link to="/legal/terms-of-service">Terms of Service</Link>
            </LinkContainer>
          </Section>

          <Section>
            <SectionTitle>Policies</SectionTitle>

            <LinkContainer>
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </LinkContainer>

            <LinkContainer>
              <Link to="/legal/electronic-consent">Eletronic Consent</Link>
            </LinkContainer>
          </Section>
        </LinksContainer>

        <Outlet />
      </Content>

      <PageFooter />
    </LegalContainer>
  );
}

export default Legal;
