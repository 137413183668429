import React, { useRef } from 'react';
import styled from 'styled-components';

import getTheme from '../../../theme';
import { FadeInDown, FadeInUp, FadeInRight } from '../../common/ScrollAnimations';
import useScrollObserver from '../../../hooks/useScrollObserver';
import { useAppContext } from '../../../contexts/AppContext';
import useSubRoute from '../../../hooks/useSubRoute';

const LearnMoreContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 45%;
  grid-template-rows: 700px;
  grid-column-gap: 5%;
  margin: 0px auto;
  position: relative;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    grid-row-gap: 32px;
    grid-template-columns: 90%;
    grid-template-rows: auto;
    justify-content: center;
    padding-bottom: 64px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1;
  justify-content: center;
  padding-left: 94px;
  position: relative;
  z-index: 10;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    padding: 100px 0px 48px 0px
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    padding: 80px 0px;
  }
`;

const Header = styled.div`
  color: #071058;
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 56px;
  font-weight: 500;
  line-height: 120%;
  margin-bottom: 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    font-size: 52px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Tablet}) {
    font-size: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.Mobile}) {
    font-size: 44px;
  }
`;

const LearnMoreButton = styled.a`
  background: #4054ED;
  border-radius: 6px;
  color: ${(props) => props.theme.colors.White};
  font-size: 21px;
  height: 58px;
  line-height: 160%;
  padding: 14px 35px 14px 35px;
  transition: background .25s ease-in-out;
  width: 184px;

  &:hover {
    background: #162ee9;
  }
`;

const Right = styled.div`
  align-items: center;
  display: flex;
  grid-column: 2;
  height: 100%;
  justify-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.Laptop}) {
    grid-column: 1;
  }
`;

const Graphic = styled.img`
  width: 100%;
`;

function LearnMore() {
  const { brand, partnerId, program } = useAppContext();
  const learnMoreRef = useRef<HTMLDivElement>(null);
  const subRoute = useSubRoute();
  const isVisible = useScrollObserver({
    ref: learnMoreRef,
    threshold: 0,
  });
  const theme = getTheme(brand);

  return (
    <LearnMoreContainer ref={learnMoreRef}>
      <Left>
        <FadeInDown>
          <Header>
            Let Mesa help your
            <br />
            business get paid faster
          </Header>
        </FadeInDown>

        <FadeInRight>
          <LearnMoreButton
            href={`${CONFIG.ui.vendorUIUrl}/faq?partnerId=${partnerId}${subRoute ? `&subRoute=${subRoute}` : ''}${program ? `&programCode=${program}` : ''}`}
            target="_blank"
          >
            Learn More
          </LearnMoreButton>
        </FadeInRight>
      </Left>

      {isVisible && (
        <FadeInUp>
          <Right>
            <Graphic src={theme.dynamicContent.tabletSignInImage} />
          </Right>
        </FadeInUp>
      )}
    </LearnMoreContainer>
  );
}

export default LearnMore;
