import React, {
  ReactElement,
  KeyboardEvent,
  useState,
  FormEvent,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';
import {
  Button,
  Input,
  Modal,
  useValidation,
  utils,
} from '@mesa-labs/mesa-ui';
import { types as api } from '@mesa-labs/mesa-api';

import { createSignUpInquiry } from '../../../api';
import cypressSelectors from '../../../../cypress-selectors.json';
import { useAppContext } from '../../../contexts/AppContext';

const ContactUsContainer = styled.div`
  background: ${(props) => props.theme.colors.White};
  border-radius: 15px;
  color: #2B2B2B;
  height: 100%;
  padding: 36px;
  text-align: left;
  width: 100%;
`;

const HeaderContainer = styled.div``;

const Header = styled.div`
  font-family: ${(props) => props.theme.fonts.secondary};
  font-size: 32px;
  font-weight: 600;
`;

const ContactDetailsContainer = styled.div`
  background: #EBF1FF;  
  border-radius: 8px;
  border: 1px solid #C2D6FF;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  margin: 16px 0px 20px 0px;
  padding: 12px;
`;

const ContactDetail = styled.div`
  display: flex;  
  flex-direction: column;
  gap: 4px;
`;

const ContactDetailLabel = styled.p`
  color: #20232D;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.096px;  
`;

const ContactDetailValue = styled.a`
  color: #0075FF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;

  &:hover {
    text-decoration: underline;
  }
`;

const SubHeader = styled.div`
  border-top: 1px solid #D0D5DD;
  font-size: 16px;
  font-weight: 500;
  padding-top: 16px;
  margin-bottom: 24px;
`;

const FormContainer = styled.div``;

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const SubmitButtonContainer = styled.div`
  align-items: center;
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr 2fr;
  justify-content: center;
  width: 100%;

  & > div {
    width: 100%;
  }
`;

type ContactUsModalProps = {
  onClose: () => void;
  setStatus: (status: string) => void;
  setMessage: (message: string) => void;
  setTitle: (title: string) => void;
  open: boolean;
};

function ContactUsModal({
  onClose,
  setStatus,
  setMessage,
  setTitle,
  open,
}: ContactUsModalProps): ReactElement {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { brand, partnerId } = useAppContext();

  const {
    results: validationResults,
    passed: validationPassed,
  } = useValidation({
    email,
    name: companyName,
    firstName: name,
    phoneNumber,
  });

  const canContinue = (
    validationPassed
    && typeof recaptchaToken === 'string'
    && recaptchaToken.length > 0
  );

  const handleSubmit = async (evt: FormEvent) => {
    evt.stopPropagation();

    setLoading(true);

    try {
      await createSignUpInquiry({
        companyName,
        email,
        phoneNumber,
        name,
        recaptchaToken,
        partner: api.getPartnerName(partnerId || api.Partners.NO_PARTNER),
      });

      setStatus('success');

      if (brand === 'unbranded') {
        setTitle('Submitted');
        setMessage('Thank you for your inquiry. A member of our team will reach out using the contact information you\'ve provided.');
      } else {
        setMessage('Success! We\'ll get back to you as soon as possible.');
      }
    } catch (err) {
      setStatus('error');
      setMessage('There was an error processing your request. Please try again momentarily.');
    }

    setLoading(false);
    onClose();
  };

  return (
    <Modal
      width={580}
      top={80}
      onClose={onClose}
      showCloseButton
      open={open}
    >
      <ContactUsContainer>
        <HeaderContainer>
          <Header>Contact Us</Header>

          <ContactDetailsContainer>
            <ContactDetail>
              <ContactDetailLabel>Email</ContactDetailLabel>
              <ContactDetailValue href="mailto:support@joinmesa.com">support@joinmesa.com</ContactDetailValue>
            </ContactDetail>

            <ContactDetail>
              <ContactDetailLabel>Phone</ContactDetailLabel>
              <ContactDetailValue href="tel:312-883-0565">312-883-0565</ContactDetailValue>
            </ContactDetail>
          </ContactDetailsContainer>

          <SubHeader>
            Or, let us know how best to get in touch below:
          </SubHeader>
        </HeaderContainer>

        <FormContainer>
          <InputContainer>
            <Input
              dataCy={cypressSelectors.contactNameInput}
              label="Contact Name"
              required
              type="text"
              value={name}
              onChange={(evt) => setName(evt.target.value)}
              validationResult={validationResults.firstName}
            />

            <Input
              dataCy={cypressSelectors.companyNameInput}
              label="Company Name"
              required
              type="text"
              value={companyName}
              onChange={(evt) => setCompanyName(evt.target.value)}
              validationResult={validationResults.name}
            />

            <Input
              dataCy={cypressSelectors.contactUsEmailInput}
              label="Email"
              required
              type="text"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
              validationResult={validationResults.email}
            />

            <Input
              dataCy={cypressSelectors.phoneNumberInput}
              label="Phone Number"
              type="text"
              value={phoneNumber}
              onKeyDown={
                (evt: KeyboardEvent<HTMLInputElement>) => setPhoneNumber(
                  utils.formatPhoneNumber(phoneNumber + evt.key, phoneNumber, evt.key),
                )
              }
              validationResult={validationResults.phoneNumber}
            />

            <ReCAPTCHA
              sitekey={CONFIG.google.recaptchaSiteKey}
              onChange={(token: string | null) => setRecaptchaToken(token || '')}
              size="normal"
            />
          </InputContainer>

          <SubmitButtonContainer>
            <Button
              onClick={onClose}
              text="Cancel"
              color="secondary"
            />
            <Button
              dataCy={cypressSelectors.submitButton}
              text="Submit"
              onClick={handleSubmit}
              disabled={!canContinue}
              isLoading={loading}
              width="100%"
              color="primary"
            />
          </SubmitButtonContainer>
        </FormContainer>
      </ContactUsContainer>
    </Modal>
  );
}

export default ContactUsModal;
